import './App.css';
import React from 'react';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import {Document, Page, pdfjs} from 'react-pdf';
import { Checkbox } from '@mui/material';
import { Button } from '@mui/material';
import { Alert } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Response{
  firstName: string | null,
  lastName: string | null,
  email: string | null,
  numberAttending: string | null,
  dietaryRequirements: string | null,
  attending: boolean | null
}

/**
 * Send a HTTP request with the given body to the given route
 * @param {obj} body content to be sent over HTTP request
 * @param {string} route route to send request on
 * @param {string} method the method of the HTTP request
 * @return {Promise} promise that resolves when request has returned
 */
export const api = (body, method) => {
  const options = {
    method,
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  return fetch('https://frame.ddwyer.dev/respond', options)
      .then(
          async (r) => {
            if (r.status !== 200) {
              const data = await r.json();
              return Promise.reject(data.error);
            }
            return r.json();
          },
      ).catch((err) => {
        return Promise.reject(err);
      })
};

function App() {
  const [response, setResponse] = React.useState<Response>({firstName: null, lastName: null, email: null, dietaryRequirements: null, numberAttending: null, attending:  true});
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [displayInvite, setDisplayInvite] = React.useState<boolean>(true);
  const [errorTimeout, setErrorTimeout] = React.useState<NodeJS.Timeout | undefined>(undefined);

  const updateResponse = (field: string, value: string | boolean) => {
    setResponse({...response, [field]: value});
  }

  const timeoutError= () => {
    clearTimeout(errorTimeout);
    setErrorTimeout(setTimeout(() => setError(null), 5000));
    console.log(errorTimeout);
  }

  const timeoutSuccess= () => {
    setTimeout(() => setSuccess(null), 5000);
  }

  const validate = (): boolean => {
    if (response.firstName == null || response.firstName === ''){
      setError('Please enter your first name');
      timeoutError();
      return false;
    }
    if (response.lastName == null || response.lastName === ''){
      setError('Please enter your last name');
      timeoutError();
      return false;
    }
    if (response.email == null || response.email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(response.email)){
      setError('Please enter a valid email');
      timeoutError();
      return false;
    }
    if (!validNumber()){
      setError('Please enter a valid number of people attending');
      timeoutError();
      return false;
    }
    return true;
  }

  const submitResponse = (input: Response) => {
    if (!validate()) return;
    // if (!input.attending) input['numberAttending'] = '0';
    
    api(input, "PUT").then((data) => {
      setSuccess(data.response);
      timeoutSuccess();
    }).catch((err: string) => {
      console.log('error!');
      setError("Failed to reach server. If this problem persists, please contact Dominic or John to RSVP.");
      timeoutError();
    });
  }

  const validNumber = (): boolean | undefined => {
    if (response.numberAttending == null) return false;
    return parseInt(response.numberAttending) >= 0;
  }

  return (
    <div className="App">
      {error ? 
        <Alert severity="error"
               action={
               <IconButton
                 aria-label="close"
                 color="inherit"
                 size="small"
                 onClick={() => {
                   setError(null);
                 }}
                >
              <CloseIcon fontSize="inherit" />
            </IconButton>}
        >{error}</Alert>
        : <></>
      }
      {success ? 
        <Alert severity="success"
               action={
                 <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setSuccess(null);
                  }}
                >
              <CloseIcon fontSize="inherit" />
            </IconButton>
                }>{success}</Alert>
        : <></>
      }
      <header>
        <Typography className='title' variant="h5">
          Respond to Dominic and John's 60th Birthday!
        </Typography>
        <Box className='bodyContainer'>
          {displayInvite
            ? <Box className='invitePdf'>
                <Document file={'https://frame.ddwyer.dev/invite'} className='inviteContainer' onLoadError={(e) => {console.log(e); setError("Failed to reach server. If this problem persists, please contact Dominic or John to RSVP."); timeoutError()}}>
                    <Page pageNumber={1}
                          className='invite' 
                          renderTextLayer={false}
                          renderAnnotationLayer={false}/>
                </Document>
                <Button variant="contained" className='submit-btn' onClick={() => setDisplayInvite(false)}>
                  RSVP
                </Button>
              </Box>
            :
              <Box className='invite-response-container'>
                <Box className='response-div'>
                  <TextField
                    className='input'
                    required
                    id="outlined-required"
                    label="First Name (required)"
                    onChange={(event) => updateResponse('firstName', event.target.value)}
                  />
                  <TextField
                    className='input'
                    required
                    id="outlined-required"
                    label="Last Name (required)"
                    onChange={(event) => updateResponse('lastName', event.target.value)}
                  />
                  <TextField
                    className='input'
                    required
                    id="outlined-required"
                    label="Email"
                    onChange={(event) => updateResponse('email', event.target.value)}
                  />
                  <TextField
                    className='input'
                    id="outlined-required"
                    label="Dietary requirements"
                    onChange={(event) => updateResponse('dietaryRequirements', event.target.value)}
                  />
                  <TextField
                    error={!validNumber()}
                    className='input'
                    id="outlined-required"
                    type="number"
                    label="Confirm Number Attending"
                    onChange={(event) => updateResponse('numberAttending', event.target.value)}
                  />
                  <Box className='attending'>
                    <Typography className='attending-label'>
                      Attending?
                    </Typography>
                    <Checkbox defaultChecked
                              onChange={(event) =>updateResponse('attending', event.target.checked)}/>
                  </Box>
                  <Button variant="contained" className='submit-btn' onClick={() => submitResponse(response)}>
                    Submit Response
                  </Button>
                </Box>      
              </Box>
        }
        </Box>
      </header>
    </div>
  );
}

export default App;
